<template>
  <div>
    <section class="query_form">
      <div class="container">
        <div class="row">
          <div class="col-md-12 bg-light shadow-lg rounded p-5">
            <h4 class="fw-bold m-0 mb-3">Ticket List</h4>
            <!-- heading  -->
            <div class="table-responsive">
              <table class="table table-hover" style="cursor: pointer">
                <thead>
                  <tr>
                    <th
                      class="text-capitalize"
                      v-for="(data, index) in table_headings"
                      :key="index"
                    >
                      {{ data }}
                    </th>
                  </tr>
                </thead>
                <!-- thead  -->

                <tbody>
                  <tr v-if="ticketlist.length == 0">
                    <td colspan="7">
                      <div class="d-flex justify-content-center" v-if="loading">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <span v-else class="d-flex justify-content-center">
                        No Ticket Available
                      </span>
                    </td>
                  </tr>

                  <tr v-else v-for="(list, index) in ticketlist" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ list.category.name }}</td>
                    <td class="text-truncate" style="max-width: 135px">
                      {{ list.title }}
                    </td>
                    <td>{{ list.author_name }}</td>
                    <td>{{ list.author_email }}</td>
                    <td>
                      {{
                        new Date(list.created_at).toLocaleDateString(
                          "en-US",
                          options
                        )
                      }}
                    </td>
                    <td>
                      <span
                        :style="changeclr(list.status)"
                        class="px-2 py-1 rounded w-100 d-block text-center"
                        >{{ list.status }}</span
                      >
                    </td>
                    <td>
                      <router-link
                        :to="`/ticketinfo/${list.id}`"
                        class="btn btn-outline-dark border-0 shadow-none"
                        ><i class="fas fa-eye"></i
                      ></router-link>
                    </td>
                  </tr>
                </tbody>
                <!-- tbody  -->
                <!-- <tbody v-else>
                  <tr class="elseRow">
                    <td colspan="7">No Ticket Available</td>
                  </tr>
                </tbody> -->
                <!--No data found case-->
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.query_form {
  padding: 50px 0;
}
</style>
<script>
import ApiClass from "@/api/api";
export default {
  setup() {},
  data: () => ({
    table_headings: [
      "s no.",
      "ticket type",
      "title",
      "name",
      "email",
      "Ticket Generated",
      "status",
      "action",
    ],
    ticketlist: [],
    loading: false,
    options: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  }),

  async mounted() {
    this.loading = true;
    var url = "ticket/get";
    await ApiClass.getRequest(url, true).then((response) => {
      this.ticketlist = response.data.data;
      this.loading = false;
      console.log("list==", this.ticketlist);
    });
  },
  methods: {
    changeclr(status) {
      if (status == "open") {
        return {
          color: "#256029",
          backgroundColor: "#c8e6c9",
        };
      } else if (status == "close") {
        return {
          color: "#c63737",
          backgroundColor: "#ffcdd2",
        };
      } else {
        return {
          color: "#805b36",
          backgroundColor: "#ffd8b2",
        };
      }
    },
  },
};
</script>