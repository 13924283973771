<template>
  <div>
    <section class="query_form">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 bg-light shadow-lg rounded p-5">
            <form class="row" @submit.prevent="submit">
              <div class="col-md-12 mb-3">
                <label class="form-label">Title</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter the title"
                  v-model.trim="$v.form.title.$model"
                />
                <div class="error" v-if="submitted && !$v.form.title.required">
                  Title is required
                </div>
              </div>
              <!--title-->

              <div class="col-md-12 mb-3">
                <label for="validationCustom04" class="form-label"
                  >Choose Category</label
                >
                <select
                  class="form-select"
                  id="validationCustom04"
                  v-model="form.category_id"
                >
                  <option
                    v-for="(data, index) in ticketlist"
                    :key="index"
                    :value="data.id"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </div>
              <!-- Select  -->

              <div class="col-md-12 mb-3">
                <label class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter the name"
                  v-model.trim="$v.form.author_name.$model"
                />
                <div
                  class="error"
                  v-if="submitted && !$v.form.author_name.required"
                >
                  Name is required
                </div>
              </div>
              <!-- Name  -->
              <div class="col-md-12 mb-3">
                <label class="form-label">Email</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Email"
                  v-model.trim="$v.form.author_email.$model"
                />
                <div
                  class="error"
                  v-if="submitted && !$v.form.author_email.required"
                >
                  Email is required
                </div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.author_email.email"
                >
                  This must be an email
                </div>
              </div>
              <!-- Email  -->
              <div class="col-md-12 mb-3">
                <label class="form-label">Query</label>
                <textarea
                  class="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  rows="5"
                  v-model.trim="$v.form.content.$model"
                ></textarea>
                <div
                  class="error"
                  v-if="submitted && !$v.form.content.required"
                >
                  Query is required
                </div>
              </div>
              <!-- Query  -->
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-outline-dark col-6">
                  Submit
                </button>
              </div>
              <!-- Query  -->
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.query_form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
</style>
<script>
import ApiClass from "@/api/api";
import { required, email } from "vuelidate/lib/validators";
// import axios from "axios";

export default {
  setup() {},
  data: () => ({
    ticketlist: [],
    form: {
      title: "",
      category_id: 1,
      author_name: "",
      author_email: "",
      content: "",
    },
    submitted: false,
  }),
  validations: {
    form: {
      title: { required },
      author_name: { required },
      author_email: { required, email },
      content: { required },
    },
  },
  async mounted() {
    var url = "ticket_type/get";
    await ApiClass.getRequest(url, true).then((response) => {
      this.ticketlist = response.data.data;
      this.form.category_id = response.data.data[0]?.id;
    });
  },
  methods: {
    async submit() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      //   console.log(this.form);

      await ApiClass.postRequest("ticket/create", true, this.form).then(
        (response) => {
          console.log(response);
          if (response.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.form = [];
            this.submitted = false;
            return this.$router.push("exchange/BTCUSDT");
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.submitted = false;
          }
        }
      );
    },
  },
};
</script>
