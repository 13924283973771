<template>
  <div>
    <section class="ticket_info">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row shadow-lg bg-light p-5 rounded">
              <div class="col-md-12">
                <h4 class="fw-bold m-0 mb-3">Ticket Info</h4>
              </div>
              <!-- Name  -->
              <div class="col-md-12">
                <label class="fw-bold"
                  >Name:
                  <span class="fw-normal text-capitalize">{{
                    upperData.author_name
                  }}</span></label
                >
              </div>
              <!-- Name  -->
              <div class="col-md-12 mb-3">
                <label class="fw-bold"
                  >Status
                  <span
                    :style="changeclr(upperData.status)"
                    class="px-2 py-1 rounded w-100 text-center"
                    >{{ upperData.status }}</span
                  ></label
                >
              </div>
              <!-- Status  -->
              <div class="col-md-12 mb-3">
                <label class="fw-bold">Comments</label>
                <div class="previous_data border">
                  <div v-if="commentData.length == 0">
                    <div class="d-flex justify-content-center" v-if="loading">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                      No Comment Available
                    </div>
                  </div>

                  <div
                    v-else
                    class="previous_chat_data border d-flex mb-2"
                    v-for="(data, index) in commentData"
                    :key="index"
                  >
                    <div class="user_img">
                      <i class="fas fa-user-tie fs-1 me-2"></i>
                    </div>
                    <div class="user_date_comment_box">
                      <div class="user_date_box d-flex justify-content-between">
                        <span class="fw-bold">{{ data.user.name }}</span>
                        <span class="float-right">
                          {{
                            new Date(data.created_at).toLocaleDateString(
                              "en-US",
                              options
                            )
                          }}
                        </span>
                      </div>
                      <div class="user_name_comment">
                        <p class="m-0">{{ data.comment }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 1  -->
                </div>
              </div>
              <!-- Previous Data  -->
              <form @submit.prevent="submit" v-if="statusChk != 'close'">
                <div class="col-md-12 mb-3">
                  <label class="fw-bold">Comment</label>
                  <textarea
                    class="form-control shadow-none"
                    placeholder="Leave a comment here"
                    style="height: 100px"
                    v-model.trim="$v.form.comment.$model"
                  ></textarea>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.comment.required"
                  >
                    Comment is required
                  </div>
                </div>
                <!-- Input field  -->
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="
                      btn btn-outline-dark
                      col-5 col-md-4 col-lg-3 col-xl-2
                    "
                  >
                    Submit
                  </button>
                </div>
              </form>
              <!-- Button  -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ApiClass from "@/api/api";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TicketInfo",
  data: () => ({
    commentData: [],
    upperData: [],
    form: {
      ticket_id: "",
      comment: "",
    },
    submitted: false,
    statusChk: "",
    loading: false,
    options: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  }),
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  mounted() {
    this.apiGet();
  },
  methods: {
    async apiGet() {
      this.loading = true;
      this.form.ticket_id = this.$route.params.id;
      var url = "ticket/get/" + this.form.ticket_id;
      await ApiClass.getRequest(url, true).then((response) => {
        console.log("info==", response);
        this.upperData = response.data.data;
        this.commentData = response.data.data.comments;
        this.statusChk = response.data.data.status;
        this.loading = false;
      });
    },
    submit() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      // console.log(this.form);
      ApiClass.postRequest("ticket_comment/create", true, this.form).then(
        (response) => {
          console.log(response);
          if (response.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            // this.form = [];
            this.form.comment = "";
            this.submitted = false;
            this.apiGet();
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.submitted = false;
          }
        }
      );
    },
    changeclr(status) {
      if (status == "open") {
        return {
          color: "#256029",
          backgroundColor: "#c8e6c9",
        };
      } else if (status == "close") {
        return {
          color: "#c63737",
          backgroundColor: "#ffcdd2",
        };
      } else {
        return {
          color: "#805b36",
          backgroundColor: "#ffd8b2",
        };
      }
    },
  },
};
</script>

<style scoped>
section.ticket_info {
  padding: 50px 0;
}

.previous_data {
  padding: 10px 10px;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: scroll;
}

.previous_chat_data {
  padding: 10px 10px;
  border-radius: 5px;
}

.user_date_comment_box {
  width: 100%;
}
</style>